exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-arthritis-js": () => import("./../../../src/pages/services/arthritis.js" /* webpackChunkName: "component---src-pages-services-arthritis-js" */),
  "component---src-pages-services-back-pain-js": () => import("./../../../src/pages/services/back-pain.js" /* webpackChunkName: "component---src-pages-services-back-pain-js" */),
  "component---src-pages-services-injury-rehab-js": () => import("./../../../src/pages/services/injury-rehab.js" /* webpackChunkName: "component---src-pages-services-injury-rehab-js" */),
  "component---src-pages-services-massage-js": () => import("./../../../src/pages/services/massage.js" /* webpackChunkName: "component---src-pages-services-massage-js" */),
  "component---src-pages-services-sciatica-js": () => import("./../../../src/pages/services/sciatica.js" /* webpackChunkName: "component---src-pages-services-sciatica-js" */),
  "component---src-pages-services-shoulder-pain-js": () => import("./../../../src/pages/services/shoulder-pain.js" /* webpackChunkName: "component---src-pages-services-shoulder-pain-js" */),
  "component---src-pages-services-trapped-nerve-js": () => import("./../../../src/pages/services/trapped-nerve.js" /* webpackChunkName: "component---src-pages-services-trapped-nerve-js" */),
  "component---src-pages-the-team-js": () => import("./../../../src/pages/the-team.js" /* webpackChunkName: "component---src-pages-the-team-js" */)
}

